<template>
  <div class="sub-container flex-sb">
    <div class="content col" id="application-detail">
      <section class="section">
        <h3 class="section-title">신청 상세정보</h3>
        <table class="tbl-view app-detail-info">
          <colgroup>
            <col class="head" />
            <col class="data" />
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th>신청일</th>
            <td>{{ convertDateFromat(application.createdAt) }}</td>
            <th>신청항목</th>
            <td>{{ application.applicationItem }}</td>
          </tr>
          <tr>
            <th>이름</th>
            <td>{{ application.name }}</td>
            <th class="multi-line">주민번호<br />(외국인번호)</th>
            <td>
              {{ application.socialSecurityNumber }}
              <span class="bar"></span>
              {{ application.nationality }}인{{ application.sex }}성
            </td>
          </tr>
          <tr>
            <th>휴대폰번호</th>
            <td>{{ application.mobile }}</td>
            <th>이메일</th>
            <td>{{ application.email }}</td>
          </tr>
          <tr>
            <th>주소</th>
            <td colspan="3">
              {{ application.address1 }} {{ application.address2 }} ({{
                application.postcode
              }})
            </td>
          </tr>
          <tr>
            <th class="multi-line">보호자<br />(연락처)</th>
            <td>
              {{ application.protectorName }}
              <span class="bar"></span>
              {{ application.protectorMobile }}
            </td>
            <th>신청자와 관계</th>
            <td>{{ application.protectorRelationship }}</td>
          </tr>
          <tr>
            <th>보험유형</th>
            <td>
                <span class="insurance">{{
                    application.healthInsuranceType
                  }}</span>
            </td>
            <th>가족수 | 보험료</th>
            <td>
              {{ application.familyCount }}인
              <span class="bar"></span>
              {{ application.premium }}원
            </td>
          </tr>
          <tr>
            <th>실비보험 소지여부</th>
            <td colspan="3">{{ application.lifeInsuranceExist }}</td>
          </tr>
          <tr>
            <th>치료내용</th>
            <td colspan="3">{{ application.treatmentDetails }}</td>
          </tr>

          <tr>
            <th>총비용</th>
            <td>{{ application.totalAmount }}</td>
            <th>지원비용</th>
            <td>{{ application.supportAmount }}</td>
          </tr>
          <tr>
            <th>자기부담금</th>
            <td colspan="3">{{ application.selfAmount }}</td>

          </tr>
          <tr>
            <th>병원측 메모</th>
            <td colspan="3">{{ application.hospitalMemo }}</td>

          </tr>
          <tr
            v-if="application.document != undefined"
            data-html2canvas-ignore="true"
          >
            <th>첨부서류</th>
            <td colspan="3">
              <div class="attach-file">
                <div class="attach-file-sub">
                  <a
                    class="btn-down-file"
                    :href="application.document.residentRegister"
                    @click.prevent="
                        downloadItem(application.document.residentRegister)
                      "
                  ><span class="txt">주민등록등본</span></a
                  >
                  <a
                    class="btn-down-file"
                    :href="application.document.healthInsurancePayments"
                    @click.prevent="
                        downloadItem(
                          application.document.healthInsurancePayments
                        )
                      "
                  ><span class="txt">건강보험납부확인서</span></a
                  >
                  <a
                    class="btn-down-file"
                    :href="application.document.insurerResidentRegister"
                    @click.prevent="
                        downloadItem(
                          application.document.insurerResidentRegister
                        )
                      "
                  ><span class="txt">보험자주민등록등본</span></a
                  >
                  <a
                    q
                    :href="application.document.familyRelationsCertificate"
                    @click.prevent="
                        downloadItem(
                          application.document.familyRelationsCertificate
                        )
                      "
                  ><span class="txt">가족관계증명서</span></a
                  >
                  <a
                    class="btn-down-file"
                    :href="application.document.healthInsuranceCertificate"
                    @click.prevent="
                        downloadItem(
                          application.document.healthInsuranceCertificate
                        )
                      "
                  ><span class="txt">건강보험보험자격확인서</span></a
                  >
                  <a
                    class="btn-down-file"
                    :href="application.document.oldAgePensionerCertificate"
                    @click.prevent="
                        downloadItem(
                          application.document.oldAgePensionerCertificate
                        )
                      "
                  ><span class="txt">기초수급자확인서</span></a
                  >
                  <a
                    class="btn-down-file"
                    :href="application.document.basicLivelihoodCertificate"
                    @click.prevent="
                        downloadItem(
                          application.document.basicLivelihoodCertificate
                        )
                      "
                  ><span class="txt">기초수급자확인서</span></a
                  >
                </div>
                <div class="attach-file-sub">
                  <button
                    class="btn-down-all"
                    @click.prevent="
                        downloadItemAll(
                          application.document.healthInsurancePayments,
                          application.document.insurerResidentRegister
                        )
                      "
                  >
                    <span class="txt">전체 다운로드</span>
                  </button>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="application.hospital != undefined">
            <th class="multi-line">신청병원<br />진료과목</th>
            <td>
              {{ application.hospital.hospitalName }}
              <span class="bar"></span>
              {{ application.treatmentSubject }}
            </td>
            <th>지원항목</th>
            <td>{{ application.medicalCare }}</td>
          </tr>
          <tr>
            <th>생활환경 및 사연</th>
            <td colspan="3">{{ application.story }}</td>
          </tr>
          </tbody>
        </table>
        <div class="table-detail-foot flex-sb">
          <!--          <button-->
          <!--            class="btn-outline tf-btn"-->
          <!--            @click="makePDF"-->
          <!--            data-html2canvas-ignore="true"-->
          <!--          >-->
          <!--            <span class="txt ico down-sb">신청서 다운로드</span>-->
          <!--          </button>-->
          <!--          <button-->
          <!--            class="btn-confirm tf-btn"-->
          <!--            @click="openApplicationEdit"-->
          <!--            data-html2canvas-ignore="true"-->
          <!--          >-->
          <!--            <span class="txt ico apply-w">신청서 수정하기</span>-->
          <!--          </button>-->
        </div>
      </section>
    </div>
    <div class="content col">
      <section class="section">
        <h3 class="section-title">정산</h3>
        <table class="tbl-view chg-cd">
          <colgroup>
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th>진행상태</th>
            <td>
              <div class="t-radio-group" style="width:700px;">
                <div class="radio">
                  <input
                    type="radio"
                    id="radio01"
                    name="radio"
                    v-model="status"
                    value="received"
                    disabled
                  />
                  <label for="radio01">접수</label>
                </div>
                <span class="bar"></span>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio02"
                    name="radio"
                    v-model="status"
                    value="selected"
                    disabled
                  />
                  <label for="radio02">선정</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio03"
                    name="radio"
                    v-model="status"
                    value="recheck"
                    disabled
                  />
                  <label for="radio03">재확인</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio04"
                    name="radio"
                    v-model="status"
                    value="review"
                    disabled
                  />
                  <label for="radio04">심의</label>
                </div>
                <span class="bar"></span>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio012"
                    name="radio"
                    v-model="status"
                    value="first_examination"

                  />
                  <label for="radio012">초진완료</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio07"
                    name="radio"
                    v-model="status"
                    value="treatment_progress"
                    disabled
                  />
                  <label for="radio07">치료진행</label>
                </div>
                <span class="bar"></span>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio11"
                    name="radio"
                    v-model="status"
                    value="examination_completed"
                  />
                  <label for="radio11">검진완료</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio08"
                    name="radio"
                    v-model="status"
                    value="treatment_completed"
                  />
                  <label for="radio08">치료완료</label>
                </div>
              </div>
              <div
                class="t-radio-group"
                style="width:450px;margin-top: 10px;"
              >
                <div class="radio">
                  <input
                    type="radio"
                    id="radio09"
                    name="radio"
                    v-model="status"
                    value="settlement_waiting"
                    disabled
                  />
                  <label for="radio09">정산대기</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio10"
                    name="radio"
                    v-model="status"
                    value="settlement_completed"
                    disabled
                  />
                  <label for="radio10">정산완료</label>
                </div>
                <span class="bar"></span>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio06"
                    name="radio"
                    v-model="status"
                    value="cancel"
                    disabled
                  />
                  <label for="radio06">취소</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio12"
                    name="radio"
                    v-model="status"
                    value="timeout"
                    disabled
                  />
                  <label for="radio12">기간만료</label>
                </div>
                <div class="radio">
                  <input
                    type="radio"
                    id="radio05"
                    name="radio"
                    v-model="status"
                    value="drop"
                    disabled
                  />
                  <label for="radio05">탈락</label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>진행상태 내용</th>
            <td>
              <div
                class="memo-wrap"
                v-for="statusItem in application.statusList"
                v-bind:key="statusItem.applicationStatusContentsId"
              >
                <div class="date-writer">
                  <p class="txt">{{ statusItem.createdAt }}</p>
                  <p class="txt">입력자 : 진선우 [상담팀]</p>
                </div>
                <p class="memo">
                  [{{ statusItem.status }}] {{ statusItem.contents }}
                </p>
              </div>
 
            </td>
          </tr>
          </tbody>
        </table>
        <div class="view-tail" data-html2canvas-ignore="true">
          <button class="btn-go-list">
            <span class="txt go-list">목록으로</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
  name: "CalculateDetail",
  data: () => ({
    application: {},
    status: null,
    statusContents: "",
    memoContents: "",
    memo: "",
    propTitle: "mypdf",
    treatmentDetails: "",
    totalAmount: 0,
    supportAmount: 0,
    selfAmount: 0,
    hospitalMemo: "",
  }),
  mounted() {
    console.log(this.$route.path);
    console.log(this.$route.params.applicationId);
  },
  beforeMount() {
    this.getApplication();
  },

  methods: {
    async getApplication() {
      console.log("==================================================");
      console.log(this.$route.path);
      console.log("==================================================");
      const getApplicationPromise = await this.$axios.get(
        "/hospital/hospital-application/" + this.$route.params.applicationId
      );
      const [response] = await Promise.all([getApplicationPromise]);
      this.application = response.data.data;
      console.log(this.application);
      this.status = this.application.status;

      this.treatmentDetails = this.application.treatmentDetails;
      this.totalAmount = this.application.totalAmount;
      this.supportAmount = this.application.supportAmount;
      this.selfAmount = this.application.selfAmount;
      this.hospitalMemo = this.application.hospitalMemo;

      console.log("this.status " , this.status);
    },
    openApplicationEdit() {
      this.$router.push({
        path: "/application/edit/" + this.$route.params.applicationId
      });
    },
    downloadItem(url) {
      window.open(url);
    },
    downloadItemAll(url1, url2) {
      window.open(url1);
      window.open(url2);
    },
    convertDateFromat(value) {
      if (value != undefined) {
        var resultDate = new Date(value);
        const offset = resultDate.getTimezoneOffset();
        resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
        return (
          resultDate.toISOString().split("T")[0] +
          " " +
          resultDate
            .toISOString()
            .split("T")[1]
            .substring(0, 8)
        );
      } else {
        return "";
      }
    },
    async updateStatus() {
      var param = {
        applicationId: this.$route.params.applicationId,
        status: this.status,
        contents: this.statusContents,
        treatmentDetails: this.treatmentDetails,
        totalAmount: this.totalAmount,
        supportAmount: this.supportAmount,
        selfAmount: this.selfAmount,
        hospitalMemo: this.hospitalMemo,
      };
      this.$axios
        .post("/hospital/hospital-application/status", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getApplication();
            this.statusContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    async updateMemo() {
      var param = {
        applicationId: this.$route.params.applicationId,
        memo: this.memoContents
      };
      this.$axios
        .post("application/memo", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getApplication();
            this.memoContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    makePDF() {
      console.log("makePDF");
      window.html2canvas = html2canvas; //Vue.js 특성상 window 객체에 직접 할당해야한다.
      let that = this;
      let pdf = new jsPDF("p", "mm", "a4");
      let canvas = pdf.canvas;
      const pageWidth = 210; //캔버스 너비 mm
      // const pageHeight = 295 //캔버스 높이 mm
      canvas.width = pageWidth;

      let ele = document.querySelector("#application-detail");
      let width = ele.offsetWidth; // 셀렉트한 요소의 px 너비
      let height = ele.offsetHeight; // 셀렉트한 요소의 px 높이
      console.log(width + "___" + height);
      let imgHeight = (pageWidth * height) / width; // 이미지 높이값 px to mm 변환
      //let imgWidth = pageHeight * width / height;
      if (!ele) {
        console.warn("#application-detail" + " is not exist.");
        return false;
      }
      console.log("makePDF2");
      console.log(ele);
      html2canvas(ele).then(function(canvas) {
        console.log("makePDF3");
        let position = 0;
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(
          imgData,
          "png",
          0,
          position,
          pageWidth,
          imgHeight,
          undefined,
          "slow"
        );

        // //Paging 처리
        // let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
        // heightLeft -= pageHeight
        // while (heightLeft >= 0) {
        //   position = heightLeft - imgHeight
        //   pdf.addPage();
        //   pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight)
        //   heightLeft -= pageHeight
        // }

        pdf.save(
          that.application.name +
          "_" +
          that.application.applicationItem +
          ".pdf"
        );
      });
      console.log("end");
      console.log(pdf);
    }
  }
};
</script>
